var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"mainDiv",staticStyle:{"height":"80vh"},attrs:{"id":"mainDivElement"}},[_c('div',{staticClass:"d-flex justify-content-end reports"},[_c('b-dropdown',{ref:"dropdownMenuFilters",staticStyle:{"margin-bottom":"15px"},attrs:{"variant":"primary","text":_vm.$t('reporting.filters'),"right":"","menu-class":"dropdownWidth"}},[_c('div',{staticClass:"pl-2 pt-2",style:('width:' + _vm.mainDivWidth + 'px !important;')},[(this.selected_report === _vm.REPORT_IDS.InventorySheet)?_c('b-form-checkbox',{attrs:{"switch":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{staticClass:"text-dark"},[_vm._v(_vm._s(_vm.$t('general.print_current_stock')))])]},proxy:true}],null,false,2940306523),model:{value:(_vm.printCurrentStock),callback:function ($$v) {_vm.printCurrentStock=$$v},expression:"printCurrentStock"}}):_vm._e()],1),_c('div',{staticClass:"p-2",style:('width:' + _vm.mainDivWidth + 'px !important;')},[_c('validation-observer',{ref:"validation"},[_c('div',{staticClass:"row"},[(_vm.selected_report !== 'stock' && _vm.showDatePicker())?_c('div',{staticClass:"col-6 col-md-3"},[_c('b-form-group',{attrs:{"label":_vm.$t('general.date_from')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.date_from'),"rules":("required|max_value:@" + (_vm.$t('general.date_from')))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"first-day-of-week":2,"max-date":_vm.dateTo,"disabled-dates":"","is24hr":"","locale":{ masks: { input: 'DD.MM.YYYY' } },"is-required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control",domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,570092612)})],1)],1):(_vm.showDatePicker())?_c('div',{staticClass:"col-6 col-md-3"},[_c('b-form-group',{attrs:{"label":_vm.$t('general.date_to')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.date_to'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"first-day-of-week":2,"disabled-dates":"","is24hr":"","locale":{ masks: { input: 'DD.MM.YYYY' } },"is-required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control",domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1):_vm._e(),(_vm.selected_report !== 'stock' && _vm.showDatePicker())?_c('div',{staticClass:"col-6 col-md-3"},[_c('b-form-group',{attrs:{"label":_vm.$t('general.date_to')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.date_to'),"rules":("required|min_value:@" + (_vm.$t('general.date_from')))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"first-day-of-week":2,"min-date":_vm.dateFrom,"disabled-dates":"","is24hr":"","locale":{ masks: { input: 'DD.MM.YYYY' } },"is-required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control",domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3120080762)})],1)],1):_vm._e(),(_vm.showBuyerPicker())?_c('div',{staticClass:"col-6 col-md-3"},[_c('b-form-group',{attrs:{"label":_vm.$t('general.buyer')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.buyer'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"floating-filter w-100",attrs:{"appendToBody":"","label":"name","options":_vm.buyers,"clearable":false},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('span',{staticClass:"textCutSelect"},[_vm._v(" "+_vm._s(name)+" ")])]}}],null,true),model:{value:(_vm.buyer),callback:function ($$v) {_vm.buyer=$$v},expression:"buyer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2239016820)})],1)],1):_vm._e(),(_vm.filters && _vm.filters.length > 0 && _vm.filters.includes('business_units'))?_c('div',{staticClass:"col-6 col-md-3"},[_c('b-form-group',{attrs:{"label":_vm.$t('general.business_unit')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.business_unit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","selectable":function (ele) { return !(ele.id == _vm.$NilObjectId && _vm.filters.includes('disable_all_business_units')); },"label":"name","options":_vm.businessUnits,"clearable":false},on:{"input":_vm.unitChanged},model:{value:(_vm.selectedBusinessUnit),callback:function ($$v) {_vm.selectedBusinessUnit=$$v},expression:"selectedBusinessUnit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4267331878)})],1)],1):_vm._e(),(_vm.selectedBusinessUnit && _vm.selectedBusinessUnit.id != _vm.$NilObjectId && _vm.filters && _vm.filters.length > 0 && _vm.filters.includes('cash_registers'))?_c('div',{staticClass:"col-6 col-md-3",staticStyle:{"width":"50%"}},[_c('b-form-group',{attrs:{"label":_vm.$t('general.cash_register')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.cash_register'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","label":"cash_register_id","options":_vm.cashRegistersOptions},model:{value:(_vm.selectedCashRegister),callback:function ($$v) {_vm.selectedCashRegister=$$v},expression:"selectedCashRegister"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,985163460)})],1)],1):_vm._e(),(_vm.selectedBusinessUnit && _vm.selectedBusinessUnit.id != _vm.$NilObjectId && _vm.filters && _vm.filters.length > 0 && _vm.filters.includes('price_list'))?_c('div',{staticClass:"col-6 col-md-3",staticStyle:{"width":"50%"}},[_c('b-form-group',{attrs:{"label":_vm.$t('general.price_list')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.price_list'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","label":"name","options":_vm.priceLists},model:{value:(_vm.selectedPriceList),callback:function ($$v) {_vm.selectedPriceList=$$v},expression:"selectedPriceList"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3777495534)})],1)],1):_vm._e(),(_vm.filters && _vm.filters.length > 0 && _vm.filters.includes('ingredients'))?_c('div',{staticClass:"col-6 col-md-3",staticStyle:{"width":"50%"}},[_c('b-form-group',{attrs:{"label":_vm.$t('general.ingredient')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.ingredient'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","label":"name","options":_vm.ingredients},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var ingredient_code = ref.ingredient_code;
return [_c('div',[_vm._v(_vm._s(ingredient_code)+" - "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var ingredient_code = ref.ingredient_code;
return [_c('div',[_vm._v(_vm._s(ingredient_code)+" - "+_vm._s(name))])]}}],null,true),model:{value:(_vm.selectedIngredient),callback:function ($$v) {_vm.selectedIngredient=$$v},expression:"selectedIngredient"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,942928937)})],1)],1):_vm._e(),(_vm.showWarehouseFilter)?_c('div',{staticClass:"border col-6 col-md-3",staticStyle:{"width":"50%"}},[_c('b-form-group',{attrs:{"label":_vm.$t('general.warehouse')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.warehouse'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","selectable":function (ele) { return !(ele.id == _vm.$NilObjectId && _vm.filters.includes('disable_all_warehouses')); },"label":"name","options":_vm.warehouses},model:{value:(_vm.selectedWarehouse),callback:function ($$v) {_vm.selectedWarehouse=$$v},expression:"selectedWarehouse"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4107803549)})],1)],1):_vm._e(),(_vm.filters && _vm.filters.length > 0 && _vm.filters.includes('warehouses_group') && _vm.selectedWarehouse !== null && _vm.selectedWarehouse.id === _vm.$NilObjectId)?_c('div',{staticClass:"col-6 col-md-3",staticStyle:{"width":"50%"}},[_c('b-form-group',{attrs:{"label":_vm.$t('reporting.view')}},[_c('validation-provider',{attrs:{"name":_vm.$t('reporting.view'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","label":"name","options":_vm.warehouses_group_options},model:{value:(_vm.selected_warehouses_group_option),callback:function ($$v) {_vm.selected_warehouses_group_option=$$v},expression:"selected_warehouses_group_option"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3541311313)})],1)],1):_vm._e()]),_c('div',{staticClass:"mt-1"},[(_vm.showTimePicker())?_c('div',{staticClass:"d-flex justify-content-start mb-1"},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('general.start_time'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.optionsTime,"name":"Select time"},model:{value:(_vm.isStartWorkingHour),callback:function ($$v) {_vm.isStartWorkingHour=$$v},expression:"isStartWorkingHour"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2085562054)})],1)],1):_vm._e(),_c('div',{staticClass:"row"},[(_vm.showTimePicker() && !_vm.isStartWorkingHour)?_c('div',{staticClass:"col-6 col-md-3"},[_c('b-form-group',{attrs:{"label":_vm.$t('reporting.hour_from')}},[_c('validation-provider',{attrs:{"name":_vm.$t('reporting.hour_from'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticClass:"timePickerNoDate",attrs:{"first-day-of-week":2,"mode":"time","disabled-dates":"","is24hr":"","mask":{ title: 'HH MM' },"is-required":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control",domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2194354247)})],1)],1):_vm._e(),(_vm.showTimePicker() && !_vm.isStartWorkingHour)?_c('div',{staticClass:"col-6 col-md-3"},[_c('b-form-group',{attrs:{"label":_vm.$t('reporting.hour_to')}},[_c('validation-provider',{attrs:{"name":_vm.$t('reporting.hour_to'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticClass:"timePickerNoDate",attrs:{"first-day-of-week":2,"mode":"time","disabled-dates":"","is24hr":"","is-required":true,"mask":{
                                            title: 'HH MM'
                                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var inputValue = ref.inputValue;
                                        var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"form-control",domProps:{"value":inputValue}},inputEvents))]}}],null,true),model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3849179050)})],1)],1):_vm._e()])])]),_c('div',{staticClass:"d-flex justify-content-end align-items-end"},[_c('b-button',{on:{"click":function($event){return _vm.confirm()}}},[_vm._v(_vm._s(_vm.$t('general.accept')))])],1)],1)])],1),_c('div',{staticClass:"row p-0 m-0 h-100 w-100"},[_c('div',{staticClass:"col-4 pl-0 w-100 h-100"},[_c('b-card',{staticClass:"cardBodyNoPadding p-0 m-0 h-100 w-100"},[_c('div',{staticClass:"p-2",staticStyle:{"height":"100%","min-height":"100%","overflow-y":"auto"}},[_c('tree-view',{attrs:{"color":"#b4bfb5","data":_vm.computedData},on:{"clicked":_vm.itemClicked}})],1)])],1),_c('b-card',{staticClass:"col-8 w-100 h-100 mainWrapper mb-5",staticStyle:{"max-height":"100%","overflow-y":"auto"}},[_c('b-overlay',{attrs:{"show":_vm.showLoader}},[(this.selected_report)?_c('div',{staticClass:"d-flex justify-content-end pb-1"},[(this.selected_report !== _vm.REPORT_IDS.FursDateToDateReport)?_c('b-button',{on:{"click":function($event){return _vm.loadReport('pdf')}}},[_vm._v(_vm._s(_vm.$t('reporting.download_pdf')))]):_vm._e(),(this.selected_report !== _vm.REPORT_IDS.FursDateToDateReport && this.selected_report !== _vm.REPORT_IDS.InternalAct)?_c('b-button',{staticClass:"ml-2",on:{"click":function($event){return _vm.loadReport('xlsx')}}},[_vm._v(_vm._s(_vm.$t('reporting.download_excel')))]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"w-100 mb-5",domProps:{"innerHTML":_vm._s(_vm.html)}})])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }